<img width="100%" height="100px" class="img-onda-3" defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/onda/onda-3.svg" alt="Onda Costa Azul"  />
<div class="modulo modulo-destinos">
      <div class="modulo-title wow fadeIn" data-wow-delay="0.8s" *ngIf="data.mostrar_titulo">
            <h2 class="section-title-h2">{{data.titulo}}</h2>
            <h3 *ngIf="data.mostrar_subtitulo" class="section-title-h3">{{data.subtitulo}}</h3>
      </div>
      <div class="modulo-content">
            <section class="destinos">
                  <div class="container">
                        <div class="row"> 
                              <div class="col-md-4" *ngFor="let d of data.cidades;let i=index;">   
                                    <div class="item wow slideInUp" data-wow-offset="{{i}}" data-wow-delay="0.1s">
                                          <destino-item [data]="d"></destino-item>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>    
      </div>      
</div>
<img width="100%" height="100px" class="img-onda-4" defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/onda/onda-2.svg" alt="Onda Costa Azul"  />
        
